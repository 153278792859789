import React, { useState } from 'react';

export default function ImageWithHover({
  image,
  hoverImage,
  enabled,
  setEnabled,
}) {
  const [hover, setHover] = useState(false || enabled);

  return (
    <div className="relative">
      <div
        className={`w-max`}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseOut={() => {
          setHover(false);
        }}
      >
        {!enabled && setEnabled
          ? image
          : setEnabled
          ? hoverImage
          : !hover
          ? image
          : hoverImage}
      </div>
      <div className={`invisible h-0 w-0`}>{hoverImage}</div>
    </div>
  );
}
