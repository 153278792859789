import React from 'react';
import HighlightTile from './HighlightTile/index';

export default function Highlights({ highlights }) {
  return (
    <div className="grid w-full grid-flow-row grid-cols-1 gap-5 rounded-lg bg-v5-green-700 py-4 px-6 md:grid-cols-2">
      {highlights.map((highlight) => (
        <HighlightTile highlight={highlight} />
      ))}
    </div>
  );
}
