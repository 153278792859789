import React from 'react';
import MiscellaneousPoint from './MiscellaneousPoint/index';

export default function MiscellaneousPoints({ miscellaneousPoints }) {
  return (
    <div className="flex w-full flex-col items-center gap-4 rounded-lg bg-v5-green-700 p-4 md:flex-row">
      {miscellaneousPoints.map((point) => (
        <MiscellaneousPoint point={point} />
      ))}
    </div>
  );
}
