import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function DeliverableTile({ deliverable }) {
  const { title, description, icon } = deliverable || {};
  console.log(icon);

  const getIcon = () => {
    switch (title) {
      case 'Trial Session':
        return (
          <StaticImage
            width={35}
            loading="lazy"
            src="../../../../images/v5/ProgramCards/ProgramCardV2/lock.png"
            placeholder="blurred"
            alt={title}
            imgStyle={{ objectFit: 'contain' }}
          />
        );
      case 'Duration':
        return (
          <StaticImage
            width={35}
            loading="lazy"
            src="../../../../images/v5/ProgramCards/ProgramCardV2/calendar.png"
            placeholder="blurred"
            alt={title}
            imgStyle={{ objectFit: 'contain' }}
          />
        );
      case 'Scholarships':
        return (
          <StaticImage
            width={35}
            loading="lazy"
            src="../../../../images/v5/ProgramCards/ProgramCardV2/graduation.png"
            placeholder="blurred"
            alt={title}
            imgStyle={{ objectFit: 'contain' }}
          />
        );
      case 'Career Services':
        return (
          <StaticImage
            width={35}
            loading="lazy"
            src="../../../../images/v5/ProgramCards/ProgramCardV2/suitcase.png"
            placeholder="blurred"
            alt={title}
            imgStyle={{ objectFit: 'contain' }}
          />
        );
      default:
        return '';
    }
  };

  return (
    <div className="flex w-full items-center justify-start gap-4 rounded-md bg-v5-green-700 p-4 text-center">
      {getIcon()}

      <div className="flex w-full flex-col items-start">
        <div className="mt-2 text-sm text-crio-neutral-300">{title}</div>

        <div className="text-sm font-bold text-white">{description}</div>
      </div>
    </div>
  );
}
