import React from 'react';
import OR from './OR/index';
import Specialization from './Specialization/index';

export default function Specializations({ specializations }) {
  const itemElements = [];

  for (let i = 0; i < specializations.length; i += 2) {
    const specialization1 = specializations[i];
    const specialization2 = specializations[i + 1];

    const itemElement = (
      <React.Fragment key={i}>
        {<Specialization specialization={specialization1} />}
        {specialization2 && <OR />}
        {<Specialization specialization={specialization2} />}
      </React.Fragment>
    );
    itemElements.push(itemElement);
  }

  return (
    <div className="flex w-full flex-col flex-wrap items-center md:flex-row">
      {itemElements}
    </div>
  );
}
