import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import InfoCard from '../InfoCard/index';

const CareerServicesSection = React.memo(function CareerServicesSection(props) {
  return (
    <SectionLayout
      heading={
        <>
          Prepare To Break Through <br className="hidden sm:block"></br> Into
          Top Tech Jobs <br className="hidden sm:block"></br> In The Market
        </>
      }
      description={
        <>
          <div id="career-services" className=""></div>
          All of our program come with{' '}
          <strong className="font-bold text-black">
            exclusive Career Services
          </strong>{' '}
          that will equip you to use your learnings and skills to ace your next
          interview or excel in your current role.
        </>
      }
      {...props}
    >
      <div className="container">
        <div className="flex flex-wrap justify-center gap-x-4 gap-y-4">
          {data.map((ele, idx) => (
            <InfoCard
              dark
              key={idx}
              icon={ele.icon}
              title={ele.title}
            ></InfoCard>
          ))}
        </div>
      </div>
    </SectionLayout>
  );
});

export default CareerServicesSection;

let data = [
  {
    icon: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/Career/PointSeven.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    title: (
      <p class="text-v5-neutral-100">
        1:1 Interview preparation (Mock Interviews) before technical interview
        rounds with our hiring partners
      </p>
    ),
  },
  {
    icon: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/Career/PointOne.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    title: (
      <p class="text-v5-neutral-100">
        Soft-Skills training coupled with pre-training and post-training
        assessments
      </p>
    ),
  },
  {
    icon: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/Career/PointSix.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    title: (
      <p class="text-v5-neutral-100">
        Series of mock assessments and detailed interview prep sprints to ace
        top tech jobs
      </p>
    ),
  },
  {
    icon: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/Career/PointFour.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    title: (
      <p class="text-v5-neutral-100">
        Expert guidance to get your profile ready (Github, LinkedIn, Resume)
      </p>
    ),
  },
  {
    icon: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/Career/PointFive.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    title: (
      <p class="text-v5-neutral-100">
        Access to a diverse set of job opportunities with 1000+ hiring partners
      </p>
    ),
  },
];
