import React, { useState } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import RoundedLayout from '../layouts/RoundedLayout/index';
import ImageWithHover from '@components/Common/ImageWithHover/index';

const InTheNewsSection = React.memo(function InTheNewsSection() {
  return (
    <RoundedLayout roundedBg="bg-v5-green-100" className="bg-white">
      <SectionLayout heading="In The News">
        <div className="container mt-24 mb-16 sm:mt-0">
          <div className="flex h-full flex-wrap items-stretch justify-center gap-x-4 gap-y-4  sm:flex-nowrap">
            <a
              rel="noreferrer"
              href="https://yourstory.com/2019/12/india-top-50-disruptive-startups-yourstory/"
              target="_blank"
              className={` group flex  h-full cursor-pointer flex-col items-center gap-y-2 rounded-[20px] bg-v5-neutral-200 p-5 text-center transition-all hover:bg-v5-green-100 hover:text-black sm:max-w-[220px] sm:items-start sm:text-left`}
            >
              <div className="-mt-24 block sm:hidden ">
                <StaticImage
                  width={120}
                  loading="lazy"
                  src="../../../images/v5/News/Highlight.png"
                  placeholder="blurred"
                  alt="Difference"
                />
              </div>
              <div className="hidden sm:block ">
                <StaticImage
                  width={200}
                  loading="lazy"
                  src="../../../images/v5/News/Highlight.png"
                  placeholder="blurred"
                  alt="Difference"
                />
              </div>
              <p
                className={`font-bold 
                  leading-relaxed text-v5-neutral-400 group-hover:text-black`}
              >
                Featured on Yourstory's list of startups with groundbreaking
                ideas that are creating a deep impact.
              </p>
            </a>

            <div className="flex flex-col gap-y-4 sm:max-w-[550px]">
              <div className="">
                <NewsCard
                  href="https://yourstory.com/2020/05/binny-bansal-startup-upskill-product-developers-engineers"
                  image={
                    <StaticImage
                      width={100}
                      loading="lazy"
                      src="../../../images/v5/News/Yourstory-OG.png"
                      placeholder="blurred"
                      alt="Difference"
                    />
                  }
                  hoverImage={
                    <StaticImage
                      width={100}
                      loading="lazy"
                      src="../../../images/v5/News/Yourstory-Black.png"
                      placeholder="blurred"
                      alt="Difference"
                    />
                  }
                  text="“This Binny Bansal-backed startup uses real-world problems to upskill
        developers”"
                ></NewsCard>{' '}
              </div>
              <div className="">
                <NewsCard
                  href="https://www.edexlive.com/happening/2020/jun/11/just-do-it-this-bengaluru-based-start-up-enables-developers-to-learn-in-a-work-like-virtual-enviro-12567.html"
                  image={
                    <StaticImage
                      width={150}
                      loading="lazy"
                      src="../../../images/v5/News/IndianExpress-OG.png"
                      placeholder="blurred"
                      alt="Difference"
                    />
                  }
                  hoverImage={
                    <StaticImage
                      width={150}
                      loading="lazy"
                      src="../../../images/v5/News/IndianExpress-Black.png"
                      placeholder="blurred"
                      alt="Difference"
                    />
                  }
                  text="“Bengaluru-based start-up enables developers to learn in a work-like, virtual environment”"
                ></NewsCard>{' '}
              </div>
              <div className="">
                <NewsCard
                  href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/binny-bansal-leads-flipkart-mafias-rs-7-cr-funding-in-crio/articleshow/67749381.cms"
                  image={
                    <StaticImage
                      width={150}
                      loading="lazy"
                      src="../../../images/v5/News/EconomicTimes-OG.png"
                      placeholder="blurred"
                      alt="Difference"
                    />
                  }
                  hoverImage={
                    <StaticImage
                      width={150}
                      loading="lazy"
                      src="../../../images/v5/News/EconomicTimes-Black.png"
                      placeholder="blurred"
                      alt="Difference"
                    />
                  }
                  text="“Learning platform Crio raises $1 M in funding led by Binny Bansal, Udaan founders”"
                ></NewsCard>
              </div>
            </div>
          </div>
        </div>
      </SectionLayout>
    </RoundedLayout>
  );
});

const NewsCard = ({ href, image, hoverImage, text }) => {
  const [enabled, setEnabled] = useState(false);
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      onMouseOver={() => {
        setEnabled(true);
      }}
      onMouseOut={() => {
        setEnabled(false);
      }}
    >
      <div
        className={`flex flex-col items-center gap-y-2 text-center sm:items-start sm:text-left ${
          enabled ? 'bg-v5-green-100' : 'bg-v5-neutral-200'
        } rounded-[20px] p-5 transition-all`}
      >
        <div className="">
          <ImageWithHover
            image={image}
            hoverImage={hoverImage}
            enabled={enabled}
            setEnabled={setEnabled}
          ></ImageWithHover>
        </div>
        <p
          className={`font-bold  leading-relaxed ${
            enabled ? 'text-black' : 'text-v5-neutral-400'
          }`}
        >
          {text}
        </p>
      </div>
    </a>
  );
};

export default InTheNewsSection;
