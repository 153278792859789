import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function HighlightTile({ highlight }) {
  return (
    <div className="flex w-full items-start gap-2">
      <div className="flex items-center justify-center">
        <div className="h-[24px] w-[24px]">
          <StaticImage
            width={20}
            loading="lazy"
            src="../../../../images/v5/ProgramCards/ProgramCardV2/green_tick.png"
            placeholder="none"
            alt="Tick"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>

      <div className="text-sm">{highlight.title}</div>
    </div>
  );
}
