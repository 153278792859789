import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import CrioButton from '../CrioButton/index';
import { useWebEngage } from '@src/hooks/useWebEngage';

const ChatWithUsSection = React.memo(function ChatWithUsSection() {
  const { captureUserClickEvent } = useWebEngage();

  return (
    <SectionLayout>
      <div className="container flex justify-center">
        <div className="relative w-full sm:max-w-[1000px]">
          <div
            className="flex w-full  flex-col items-center bg-v5-green-100 p-6 text-center sm:items-start  sm:py-10 sm:px-16 sm:text-left"
            style={{ borderRadius: '30px' }}
          >
            <div className="sm:max-w-1/2 h-full">
              <h1 className="font-manrope font-extrabold leading-tight">
                Have any further<br className="hidden sm:block"></br> questions?
              </h1>
              <br></br>

              <div className="mb-10 sm:mb-0">
                <CrioButton
                  variant="secondary"
                  onClick={(e) => {
                    captureUserClickEvent('Chat With Us');
                    window && window.openChatBot && window.openChatBot();
                  }}
                >
                  Chat with Us
                </CrioButton>
              </div>
            </div>
            <div className="absolute right-28 -bottom-8 hidden sm:block">
              <StaticImage
                width={250}
                loading="lazy"
                src="../../../images/v5/Chat.png"
                placeholder="blurred"
                alt="Crio.Do"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div className=" -mb-14 sm:hidden ">
              <StaticImage
                width={250}
                loading="lazy"
                src="../../../images/v5/Chat.png"
                placeholder="blurred"
                alt="Crio.Do"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
});

export default ChatWithUsSection;
