import { EPrograms } from '@src/constants/data/programs/index';
import React from 'react';
import AsideSection from './AsideSection/index';
import Highlights from './Highlights/index';
import MiscellaneousPoints from './MiscellaneousPoints/index';
import Specializations from './Specializations/index';

export default function ProgramCardV2({ type, icon }) {
  const {
    title,
    description,
    highlights,
    deliverables,
    miscellaneousPoints,
    specializations,
  } = EPrograms[type] || {};

  return (
    <div className="grid w-full grid-flow-row grid-cols-1 gap-6 rounded-[20px] bg-v5-green-500 px-6 py-8 text-v5-neutral-200 lg:grid-flow-col lg:grid-cols-5">
      <div className="col-span-1 flex flex-col items-center gap-3 lg:col-span-3">
        {/* icon */}
        <div className="mb-2 w-[78px]">{icon}</div>

        {/* Heading */}
        <h5 className="text-center font-manrope font-extrabold text-v5-green-100">
          {title}
        </h5>

        {/* Description */}
        <p className="text-center leading-normal">{description}</p>

        <Highlights highlights={highlights} />

        {miscellaneousPoints && (
          <MiscellaneousPoints miscellaneousPoints={miscellaneousPoints} />
        )}

        {specializations && (
          <Specializations specializations={specializations} />
        )}
      </div>

      <AsideSection deliverables={deliverables} type={type} />
    </div>
  );
}
