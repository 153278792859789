import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function NsdcSection({ extendedClassName }) {
  return (
    <div className={`container ${extendedClassName && extendedClassName}`}>
      <div className="flex w-full flex-col items-center justify-around gap-x-4 gap-y-8 rounded-[30px] bg-[#E1F1EA] px-0 py-8 md:flex-row md:px-6">
        <div className="w-[280px] md:w-[320px]">
          <StaticImage
            loading="lazy"
            src="../../../images/nsdc-updated.png"
            placeholder="blurred"
            alt="Certificate"
          />
        </div>

        <div className="mx-6 flex flex-col items-center justify-center gap-2 text-center md:mx-0 md:items-start md:justify-start md:gap-1 md:text-left">
          <div className="text-xl font-bold text-v5-neutral-600">
            Empowering the Next Generation of Tech Talent.
          </div>

          <div className="text-base text-v5-neutral-400">
            Approved training partner of National Skill Development Corporation
            (NSDC), India
          </div>
        </div>
      </div>
    </div>
  );
}
