import React from 'react';

export default function Specialization({ specialization }) {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-2 rounded-lg bg-v5-green-700 p-6">
      {/* Heading */}
      <h5 className="text-center font-manrope text-sm font-extrabold text-v5-green-100">
        {specialization.title}
      </h5>

      {/* Description */}
      <p className="mt-2 text-center text-sm font-bold leading-normal">
        {specialization.content}
      </p>

      {/* Description */}
      <p className="text-center text-sm leading-normal">
        {specialization.tech}
      </p>
    </div>
  );
}
