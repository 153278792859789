import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import { EProgramIDs, EPrograms } from '@src/constants/data/programs/index';
import { graphql, useStaticQuery } from 'gatsby';
import ProgramCardV2 from '@components/ProgramCardV2/index';

const programs = EPrograms;

const ProgramsSection = React.memo(function ProgramsSection({ sale }) {
  const data = useStaticQuery(graphql`
    query {
      allStrapiPrograms {
        ...allStrapiProgramsFragment
      }
    }
  `);

  return (
    <SectionLayout
      className="relative"
      heading={
        <div id="programs">
          Immersive Work Ex-based
          <br />
          Programs To Crack Jobs At The
          <br />
          Fastest-Growing Companies
        </div>
      }
      description={
        <div>
          Learn the skills you need to land a Fullstack/Backend Developers or
          SDET Job at a top product company.
          <br />
          <span className="font-bold text-black">
            {' '}
            Gain the work experience of professional developers
          </span>{' '}
          working at Amazon, Netflix, AirBnB, Flipkart, and more, with
          continuous guidance and support from our mentors.
        </div>
      }
    >
      <div className="container">
        <div className="flex flex-col gap-y-16 sm:gap-y-6">
          <ProgramCardV2
            type={EProgramIDs.FELLOWSHIP}
            icon={
              <StaticImage
                width={80}
                loading="lazy"
                src="../../../images/v5/ProgramCards/Fellowship.png"
                placeholder="blurred"
                alt="Fellowship"
              />
            }
          />
          <ProgramCardV2
            type={EProgramIDs.QA_AUTOMATION_TESTING}
            icon={
              <StaticImage
                width={80}
                loading="lazy"
                src="../../../images/v5/ProgramCards/QA.png"
                placeholder="blurred"
                alt="Fellowship"
              />
            }
          />
        </div>
      </div>
    </SectionLayout>
  );
});

export default ProgramsSection;
